@tailwind base;
@tailwind components;
@tailwind utilities;

/* SideNav.js */
.sideNav ul {
  list-style: none;
  padding: 24px;
  margin: 0;
}

.sideNav a {
  text-decoration: none;
  line-height: 32px;
  color: #6b6b6b;
  font-size: 18px;
}

.sideNav a:active,
a :focus {
  color: #74b83f;
}

/* ScenarioCard.js */
.ScenarioCard a {
  text-decoration: none;
  line-height: 32px;
  font-size: 18px;
  color: #000;
}

@media (min-width: 640px) {
  .app-content-window {
    margin-left: 16px;
  }
  .sideNavMenu,
  .sideNavMenuContainer {
    height: 85vh;
    max-width: 250px;
    margin-right: 16px;
  }
  .loginCard {
    width: 500px;
    margin: 7rem auto;
    padding: 16px 32px;
  }
}

.loginCard {
  padding: 16px 32px;
}

/* Не работает */
.loginInput input[type="text"] {
  text-transform: lowercase;
}

.deviceCard,
.ScenarioCard {
  width: 300px;
}
.devicePageHeader {
  margin: 32px 0;
}

@media (max-width: 640px) {
  .sideNavMenuContainer {
    background-color: #ffffff;
    width: 100%;
    position: fixed;
    z-index: 999;
    bottom: 0;
    border-top: 1px solid #d4d4d4;
  }
  .sideNavMenuContainer ul {
    border: none !important;
  }

  .sideNavMenu {
    display: flex;
    height: 50px;
    width: 100px;
  }

  .sideNavMenu .anticon.ant-menu-item-icon {
    margin: -6px;
  }
  .app-content-window {
    padding: 16px;
  }

  .loginCard {
    padding: 16px 0px;
    box-sizing: border-box;
    margin: 7rem 16px;
  }
  .app-content-window {
    padding-bottom: 7rem;
  }
  .deviceCard,
  .ScenarioCard {
    width: 100%;
  }
  .devicePageHeader {
    margin: 8px 0px;
  }
}
